import * as React from 'react';
import { Link } from 'gatsby';
import LayoutConsumer from '../components/layout-context';
import FancyTitle from '../components/fancy-title';
import Seo from '../components/seo';

// markup
function NotFoundPage() {
  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ fullWidth: true });
        return (
          <>
            <Seo title="404 - Seite nicht gefunden" />
            <div className="px-4 lg:px-16 flex flex-col py-20 lg:py-36 lg:pb-40 prose text-background dark:text-white max-w-none break-words lg:justify-center lg:min-h-screen">
              <FancyTitle
                text="Seite nicht gefunden"
                className="text-left"
                marginBottom="mb-0"
                tag="h1"
              />
              <p className="prose text-background-dark dark:text-white mt-0 lg:text-xl">
                <span role="img" aria-label="Pensive emoji">
                  😔
                </span>
                {' '}
                Die Seite wurde leider nicht gefunden.
              </p>
              <div>
                <Link to="/" className="button-primary no-underline">Zurück zur Startseite</Link>
              </div>
            </div>
          </>
        );
      }}
    </LayoutConsumer>
  );
}

export default NotFoundPage;
